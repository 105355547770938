import React from 'react'
import SEO from '../components/seo'
import Container from '../components/layout'
import Layout from '../containers/layout'

import { responsiveTitle1 } from '../components/typography.module.css'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' />
    <Container>
        <h1>404</h1>
    </Container>
  </Layout>
)

export default NotFoundPage
